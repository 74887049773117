* ::-webkit-scrollbar {
  display: none;
}
* {
  user-select: none;
  font-display: block !important;
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#hideTitle {
  transition: all 0.7s;
  opacity: 0;
}

#showTitle {
  transition: all 0.7s;
  opacity: 1;
}
/* 
@font-face {
  font-family: 'Fallback';
  font-weight: normal;
  src: local(Tahoma,
    Verdana,
    sans-serif);
  } */
@font-face {
  font-family: "Mellow Bold";
  src: url(../public/fonts/Futura-Bold.ttf);
}
@font-face {
  font-family: "Mellow";
  src: url(../public/fonts/Coco-Light.ttf);
}

html,
body {
  background: -webkit-linear-gradient(to right, #00223e, #ffa17f);
  background: linear-gradient(to right, #00223e, #ffa17f);
}
html,
body,
#root {
  padding: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  position: fixed;
  overflow-y: hidden;
  overflow-x: hidden;
  font-family: "Mellow", "Verdana", sans-serif;
  font-display: swap;
}
Primary Color/* #0c0712 */
.App {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}
.fadeOut {
  opacity: 0;
}
.preloader {
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 6;
  position: absolute;
  background: #ffa17f;
  background: -webkit-linear-gradient(to right, #00223e, #ffa17f);
  background: linear-gradient(to right, #00223e, #ffa17f);
  transition: all 1s;
}
.preloaderContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.heart {
  position: absolute;
  z-index: 1;
  width: 150px;
  animation: beat 0.7s infinite alternate;
  transform-origin: center;
  transition: all 1.5s 5s;
}
.heartZoom {
  width: 2000px;
}
.preloaderContent > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.preloaderContent div > p {
  font-size: 40px;
  font-weight: bold;
  color: rgb(236, 231, 231);
  position: relative;
  top: 40px;
}
.content {
  width: 100%;
  height: 100%;
  position: absolute;
  background: lightblue;
}
.content > img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  object-fit: cover;
}
.overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: rgba(1, 27, 64, 0.7);
  width: 100%;
  height: 100%;
  z-index: 2;
}
.poem {
  color: #ffffff;
  width: 100%;
  height: 650px;
  overflow-x: auto;
  position: relative;
}
.pushBox {
  width: 100%;
  height: calc(100% - 255px + 170px);
  box-sizing: border-box;
}
.title {
  text-align: center;
  width: 100%;
  color: white;
  z-index: 3;
  top: 150px;
  display: flex;
  font-size: 50px;
  word-spacing: -4px;
  font-weight: bolder;
  position: absolute;
  font-family: "Mellow Bold", "Georgia", sans-serif;
  font-display: swap;
  justify-content: center;
  align-items: center;
}
.stanza {
  margin: auto;
  margin-bottom: 25px;
  max-width: 350px;
  opacity: 0;
  transform: translateY(60px);
  transition: transform 0.6s, opacity 0.9s;
}
.line {
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 8px;
  margin-top: 0px;
  word-spacing: -1px;
  line-height: 1;
  font-size: large;
  font-weight: 600;
  color: white;
  text-align: center;
}
@keyframes beat {
  to {
    transform: scale(1.2);
  }
}
.authorTxt {
  text-decoration: none;
  color: white;
  font-size: large;
  font-weight: 600;
}
.author {
  display: flex;
  justify-content: flex-end;
  padding-right: 30px;
}
footer {
  color: white;
  text-align: center;
  font-size: small;
}
* ::selection {
  background-color: rgb(170, 161, 161, 0.5);
}
.anote {
  font-weight: bolder;
}
